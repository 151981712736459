.c-newsletter {
  background: #f0f0f0;
  float: none;
  margin: 0 auto;
  max-width: 845px;
}

@media (max-width: 46.24em) {
  .c-newsletter {
    margin-left: -12px;
    width: calc(100% + 24px);
  }
}

.c-newsletter__form {
  margin-bottom: 2rem;
}

.c-newsletter__input {
  display: flex;
  flex-wrap: wrap;
  font-family: var(--font-primary), Arial, sans-serif;
  font-style: normal !important;
  overflow: hidden;
  position: relative;
}

.c-newsletter__input input[type='text'] {
  border-radius: 3px;
  font-family: var(--font-primary), Arial, sans-serif !important;
  font-style: normal;
  height: 44px;
  width: 100%;
  padding: 0 12px;
  font-size: 16px;
}

.c-newsletter__input input[type='text']:-ms-input-placeholder {
  color: #000;
}

.c-newsletter__input input[type='text']::placeholder {
  color: #000;
}

.c-newsletter__input input[type='text']:active,
.c-newsletter__input input[type='text']:hover {
  border-color: gray;
}

.c-newsletter__input input[type='text']:focus {
  border-color: gray;
  box-shadow: 0 0 1px 1px gray;
}

.c-newsletter__input .firstname.newsletter-beta,
.c-newsletter__input .lastname.newsletter-beta {
  width: calc(50% - 6px);
}

.c-newsletter__input .firstname.newsletter-beta {
  margin-right: 6px;
}

.c-newsletter__input .lastname.newsletter-beta {
  margin-left: 6px;
}

.c-newsletter__input .email.newsletter-beta {
  margin-top: 12px;
}

@media (min-width: 46.25em) {
  .c-newsletter__input .email.newsletter-beta {
    display: inline-block;
    float: left;
    margin-right: 12px;
    width: calc(80% - 12px);
  }
}

.c-newsletter__input > button {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  bottom: 0;
  color: #000 !important;
  cursor: pointer;
  float: right;
  font-family: var(--font-primary), Arial, sans-serif;
  font-size: 16px;
  height: 44px;
  line-height: 22px;
  margin: 12px 0 5px;
  min-width: 200px;
  min-width: auto;
  padding: 7px 9px 11px;
  position: relative;
  right: 0;
  text-align: center;
  transition: transform 0.2s ease-in-out;
  width: 100%;
  z-index: 1;
  flex: none;
}

.c-newsletter__input > button:before {
  border-radius: 50%;
  bottom: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.45);
  content: '';
  height: 5px;
  left: 6px;
  opacity: 1;
  position: absolute;
  right: 6px;
  transition: opacity 0.2s ease-in-out;
  z-index: -1;
}

.c-newsletter__input > button:after {
  background: #e68b94;
  background: linear-gradient(180deg, #e68b94, #dd747e 80%);
  border-radius: 25px;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.c-newsletter__input > button:focus {
  outline: none;
}

.c-newsletter__input > button:focus:after,
.c-newsletter__input > button:hover:after {
  background: #e68b94;
}

.c-newsletter__input > button:active:after {
  background: linear-gradient(180deg, #e68b94, #f3b8bd);
}

.c-newsletter__input > button:active {
  transform: translateY(3px);
}

.c-newsletter__input > button:active:before {
  left: 3px;
  opacity: 0;
  right: 3px;
}

@media screen and (max-width: 768px) {
  .c-newsletter__input > button:hover {
    transform: translateY(3px);
  }

  .c-newsletter__input > button:hover:before {
    left: 7px;
    opacity: 0;
    right: 7px;
  }
}

@media (min-width: 46.25em) {
  .c-newsletter__input > button {
    display: inline-block;
    width: 20%;
  }
}

.c-newsletter__content {
  flex: 1;
  margin: 0;
  padding: 24px 16px;
}

@media (min-width: 46.25em) {
  .c-newsletter__content {
    padding: 40px 0 60px;
  }
}

.c-newsletter__after-submit {
  background-color: #f1f1f1;
  display: none;
  flex: 1;
  padding: 1rem 2rem;
}

.c-newsletter__after-submit .after-submit_text {
  font-weight: 400 !important;
  max-width: 650px;
  padding: 0 !important;
  text-align: center;
}

.c-newsletter__after-submit .after-submit_text h4,
.c-newsletter__after-submit .after-submit_text p {
  font-family: var(--font-primary), Arial, sans-serif;
  font-size: 16px;
  text-align: center;
}

.c-newsletter__after-submit .after-submit_text h4 {
  font-style: normal;
  line-height: 1;
  line-height: 1.2;
  margin-bottom: 0;
}

.c-newsletter__after-submit .after-submit_text .after-submit_close_button {
  background: #e68b94;
  background: linear-gradient(180deg, #e68b94, #dd747e);
  border: none;
  border-radius: 9999px;
  color: #000;
  cursor: pointer;
  font-family: var(--font-primary), Arial, sans-serif;
  font-size: 16px;
  line-height: 25px;
  padding: 7px 75px 8px;
  white-space: nowrap;
}

.c-newsletter__after-submit .after-submit_text .after-submit_close_button:focus,
.c-newsletter__after-submit .after-submit_text .after-submit_close_button:hover {
  background: #e68b94;
  outline: none;
}

.c-newsletter__after-submit .after-submit_text .after-submit_close_button:active {
  background: linear-gradient(180deg, #e68b94, #f3b8bd);
}

.c-newsletter__text {
  display: block;
  text-align: center;
  font-weight: 400 !important;
  margin: 0 auto;
  max-width: 500px;
  padding: 0 !important;
}

.c-newsletter__text h4 {
  font-size: 25px;
  font-style: normal;
  line-height: 1;
  line-height: 1.2;
  margin-bottom: 18px !important;
  margin-top: 0;
}

.c-newsletter__bottom {
  padding: 0 !important;
}

.c-newsletter__validation-error h3 {
  margin-bottom: 0;
}

.c-newsletter__integrity,
.c-newsletter__validation-error h3 strong {
  font-size: 16px;
  font-weight: 400;
}

.c-newsletter__integrity {
  color: #000;
  font-family: var(--font-primary), Arial, sans-serif;
  line-height: 1.5;
  margin: 22px 0 0;
  text-transform: none;
}

@media (min-width: 46.25em) {
  .c-newsletter__integrity {
    margin-top: 18px;
  }
}

.c-newsletter__integrity a {
  text-decoration: underline;
}

.c-newsletter__overlay {
  background-color: #f3b8bd;
  display: block;
  max-width: none;
  z-index: 2;
}

.c-newsletter__message {
  background: #fdf2f3;
  color: #000;
  display: block;
  height: 100%;
  padding: 100px 22% 0;
  text-align: center;
}

@media (min-width: 46.25em) {
  .c-newsletter__message {
    margin: 0 auto;
    max-width: 95%;
    padding-top: 70px;
  }
}

.c-newsletter__message svg {
  margin-bottom: 12px;
  width: 90px;
  display: block;
  margin: 0 auto;
}

.c-newsletter__message h4 {
  font-size: 25px;
  font-weight: 400;
  line-height: 1.2;
}

.c-newsletter__message p {
  font-family: var(--font-primary), Arial, sans-serif;
  font-size: 16px;
  line-height: 1.25;
}

._form-thank-you svg {
  width: 90px;
  display: block;
  margin: 0 auto;
}
