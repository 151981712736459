@import 'lab/newsletter.default.css';
@import 'lab/newsletter.override.css';

.bg-mb-cherry-blossom-50 {
  @apply bg-cherry-blossom-50;
}
.sm\:bg-mb-cherry-blossom-50 {
  @apply sm:bg-cherry-blossom-50;
}
.wings-mb-cherry-blossom-50 {
  @apply wings-cherry-blossom-50;
}
.sm\:wings-mb-cherry-blossom-50 {
  @apply sm:wings-cherry-blossom-50;
}

.bg-mb-cherry-blossom-500 {
  @apply bg-cherry-blossom-500;
}
.sm\:bg-mb-cherry-blossom-500 {
  @apply sm:bg-cherry-blossom-500;
}
.wings-mb-cherry-blossom-500 {
  @apply wings-cherry-blossom-500;
}
.sm\:wings-mb-cherry-blossom-500 {
  @apply sm:wings-cherry-blossom-500;
}

.bg-mb-yellow-sea-100 {
  @apply bg-yellow-sea-100;
}
.sm\:bg-mb-yellow-sea-100 {
  @apply sm:bg-yellow-sea-100;
}
.wings-mb-yellow-sea-100 {
  @apply wings-yellow-sea-100;
}
.sm\:wings-mb-yellow-sea-100 {
  @apply sm:wings-yellow-sea-100;
}

.bg-mb-yellow-sea-500 {
  @apply bg-yellow-sea-500;
}
.sm\:bg-mb-yellow-sea-500 {
  @apply sm:bg-yellow-sea-500;
}
.wings-mb-yellow-sea-500 {
  @apply wings-yellow-sea-500;
}
.sm\:wings-mb-yellow-sea-500 {
  @apply sm:wings-yellow-sea-500;
}

.bg-mb-brandy-50 {
  @apply bg-brandy-50;
}
.sm\:bg-mb-brandy-50 {
  @apply sm:bg-brandy-50;
}
.wings-mb-brandy-50 {
  @apply wings-brandy-50;
}
.sm\:wings-mb-brandy-50 {
  @apply sm:wings-brandy-50;
}

.bg-mb-brandy-400 {
  @apply bg-brandy-400;
}
.sm\:bg-mb-brandy-400 {
  @apply sm:bg-brandy-400;
}
.wings-mb-brandy-400 {
  @apply wings-brandy-400;
}
.sm\:wings-mb-brandy-400 {
  @apply sm:wings-brandy-400;
}

.bg-mb-emerald-100 {
  @apply bg-emerald-100;
}
.sm\:bg-mb-emerald-100 {
  @apply sm:bg-emerald-100;
}
.wings-mb-emerald-100 {
  @apply wings-emerald-100;
}
.sm\:wings-mb-emerald-100 {
  @apply sm:wings-emerald-100;
}

.bg-mb-emerald-600 {
  @apply bg-emerald-600;
}
.sm\:bg-mb-emerald-600 {
  @apply sm:bg-emerald-600;
}
.wings-mb-emerald-600 {
  @apply wings-emerald-600;
}
.sm\:wings-mb-emerald-600 {
  @apply sm:wings-emerald-600;
}

.bg-mb-cannon-pink-100 {
  @apply bg-cannon-pink-100;
}
.sm\:bg-mb-cannon-pink-100 {
  @apply sm:bg-cannon-pink-100;
}
.wings-mb-cannon-pink-100 {
  @apply wings-cannon-pink-100;
}
.sm\:wings-mb-cannon-pink-100 {
  @apply sm:wings-cannon-pink-100;
}

.bg-mb-cannon-pink-700 {
  @apply bg-cannon-pink-700;
}
.sm\:bg-mb-cannon-pink-700 {
  @apply sm:bg-cannon-pink-700;
}
.wings-mb-cannon-pink-700 {
  @apply wings-cannon-pink-700;
}
.sm\:wings-mb-cannon-pink-700 {
  @apply sm:wings-cannon-pink-700;
}

.bg-mb-eastern-blue-100 {
  @apply bg-eastern-blue-100;
}
.sm\:bg-mb-eastern-blue-100 {
  @apply sm:bg-eastern-blue-100;
}
.wings-mb-eastern-blue-100 {
  @apply wings-eastern-blue-100;
}
.sm\:wings-mb-eastern-blue-100 {
  @apply sm:wings-eastern-blue-100;
}

.bg-mb-eastern-blue-700 {
  @apply bg-eastern-blue-700;
}
.sm\:bg-mb-eastern-blue-700 {
  @apply sm:bg-eastern-blue-700;
}
.wings-mb-eastern-blue-700 {
  @apply wings-eastern-blue-700;
}
.sm\:wings-mb-eastern-blue-700 {
  @apply sm:wings-eastern-blue-700;
}

.bg-mb-orpiment-100 {
  @apply bg-orpiment-100;
}
.sm\:bg-mb-orpiment-100 {
  @apply sm:bg-orpiment-100;
}
.wings-mb-orpiment-100 {
  @apply wings-orpiment-100;
}
.sm\:wings-mb-orpiment-100 {
  @apply sm:wings-orpiment-100;
}

.bg-mb-orpiment-400 {
  @apply bg-orpiment-400;
}
.sm\:bg-mb-orpiment-400 {
  @apply sm:bg-orpiment-400;
}
.wings-mb-orpiment-400 {
  @apply wings-orpiment-400;
}
.sm\:wings-mb-orpiment-400 {
  @apply sm:wings-orpiment-400;
}

.bg-mb-elm-100 {
  @apply bg-elm-100;
}
.sm\:bg-mb-elm-100 {
  @apply sm:bg-elm-100;
}
.wings-mb-elm-100 {
  @apply wings-elm-100;
}
.sm\:wings-mb-elm-100 {
  @apply sm:wings-elm-100;
}

.bg-mb-elm-700 {
  @apply bg-elm-700;
}
.sm\:bg-mb-elm-700 {
  @apply sm:bg-elm-700;
}
.wings-mb-elm-700 {
  @apply wings-elm-700;
}
.sm\:wings-mb-elm-700 {
  @apply sm:wings-elm-700;
}

.bg-mb-clementine-100 {
  @apply bg-clementine-100;
}
.sm\:bg-mb-clementine-100 {
  @apply sm:bg-clementine-100;
}
.wings-mb-clementine-100 {
  @apply wings-clementine-100;
}
.sm\:wings-mb-clementine-100 {
  @apply sm:wings-clementine-100;
}

.bg-mb-clementine-400 {
  @apply bg-clementine-400;
}
.sm\:bg-mb-clementine-400 {
  @apply sm:bg-clementine-400;
}
.wings-mb-clementine-400 {
  @apply wings-clementine-400;
}
.sm\:wings-mb-clementine-400 {
  @apply sm:wings-clementine-400;
}

.bg-mb-cinnabar-100 {
  @apply bg-cinnabar-100;
}
.sm\:bg-mb-cinnabar-100 {
  @apply sm:bg-cinnabar-100;
}
.wings-mb-cinnabar-100 {
  @apply wings-cinnabar-100;
}
.sm\:wings-mb-cinnabar-100 {
  @apply sm:wings-cinnabar-100;
}

.bg-mb-cinnabar-400 {
  @apply bg-cinnabar-400;
}
.sm\:bg-mb-cinnabar-400 {
  @apply sm:bg-cinnabar-400;
}
.wings-mb-cinnabar-400 {
  @apply wings-cinnabar-400;
}
.sm\:wings-mb-cinnabar-400 {
  @apply sm:wings-cinnabar-400;
}

.bg-mb-sand-100 {
  @apply bg-sand-100;
}
.sm\:bg-mb-sand-100 {
  @apply sm:bg-sand-100;
}
.wings-mb-sand-100 {
  @apply wings-sand-100;
}
.sm\:wings-mb-sand-100 {
  @apply sm:wings-sand-100;
}

.bg-mb-sand-200 {
  @apply bg-sand-200;
}
.sm\:bg-mb-sand-200 {
  @apply sm:bg-sand-200;
}
.wings-mb-sand-200 {
  @apply wings-sand-200;
}
.sm\:wings-mb-sand-200 {
  @apply sm:wings-sand-200;
}

.bg-mb-gray-100 {
  @apply bg-gray-100;
}
.sm\:bg-mb-gray-100 {
  @apply sm:bg-gray-100;
}
.wings-mb-gray-100 {
  @apply wings-gray-100;
}
.sm\:wings-mb-gray-100 {
  @apply sm:wings-gray-100;
}
