.c-newsletter {
  background-color: #fff;
  float: left;
  margin: 12px auto;
  padding: 0;
  position: relative;
  width: 100%;
}

@media (max-width: 374px) {
  .c-newsletter {
    margin-top: 62px;
  }
}

.c-newsletter__form {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 auto;
  max-width: 700px;
  opacity: 1;
  transition: opacity 0.6s ease-in-out;
}

.c-newsletter__form .firstname.newsletter-beta {
  float: left;
  width: 50%;
}

.c-newsletter__form .lastname.newsletter-beta {
  float: right;
  width: 49%;
}

.c-newsletter__form .email.newsletter-beta {
  float: right;
  margin-top: 4px;
  width: 100%;
}

.c-newsletter__form input[type='checkbox'].newsletter-beta {
  display: none;
}

.c-newsletter__form .newsletter-beta input[type='text'] {
  margin: 0;
}

.c-newsletter__form input[type='checkbox'].newsletter-beta + label.newsletter-beta,
.c-newsletter__form input[type='checkbox'].newsletter-beta:checked + label.newsletter-beta {
  border: 1px solid #ccc;
  display: inline-block;
  height: 16px;
  margin: 4px 4px 4px 12px;
  padding: 0;
  width: 16px;
}

.c-newsletter__form input[type='checkbox'].newsletter-beta:checked + label.newsletter-beta {
  background: url(https://recept.se/assets/images/check.svg) 0 0 no-repeat;
}

.c-newsletter__divider {
  display: none;
}

.c-newsletter__content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 40px;
}

@media (max-width: 400px) {
  .c-newsletter__content {
    margin-top: 0;
  }
}

@media (max-width: 61.24em) {
  .c-newsletter__content {
    flex-direction: row;
  }
}

.c-newsletter__image {
  margin: 0;
  min-height: 150px;
  min-width: 31%;
  opacity: 1;
  padding-left: 31px;
  position: relative;
  transition: opacity 0.6s ease-in-out;
  width: 31%;
}

.c-newsletter__image .column.image {
  padding: 0;
}

.c-newsletter__image--mobile {
  display: none;
  margin: 0 20px 10px;
  max-width: 150px;
  width: calc(50% - 20px);
}

@media (max-width: 61.24em) {
  .c-newsletter__image--mobile {
    display: block;
  }
}

@media (max-width: 374px) {
  .c-newsletter__image--mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
    width: 100%;
  }

  .c-newsletter__image--mobile img {
    margin-top: -50px;
    position: relative !important;
  }
}

.c-newsletter__image--desktop {
  display: block;
}

@media (max-width: 61.24em) {
  .c-newsletter__image--desktop {
    display: none;
  }
}

.c-newsletter__image figure {
  margin: 0;
}

.c-newsletter__image figure figcaption {
  display: none;
}

.c-newsletter__text {
  padding: 20px 40px 20px 0;
  text-align: center;
}

@media (max-width: 61.24em) {
  .c-newsletter__text {
    padding: 10px 10px 10px 0;
  }
}

@media (max-width: 374px) {
  .c-newsletter__text {
    padding-left: 10px;
  }
}

.c-newsletter__text h4 {
  color: #000;
  font-family: var(--font-primary), Arial, sans-serif;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 5px;
  text-transform: none;
}

@media (max-width: 61.24em) {
  .c-newsletter__text h4 {
    font-size: 25px;
  }
}

.c-newsletter__text p {
  color: #000;
  font-family: var(--font-primary), Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-transform: none;
}

@media (max-width: 61.24em) {
  .c-newsletter__text p {
    font-size: 16px;
  }
}

.c-newsletter__text :last-child {
  margin-bottom: 0;
}

.c-newsletter__text:first-child {
  padding-left: 40px;
}

@media (max-width: 61.24em) {
  .c-newsletter__text:first-child {
    padding-left: 10px;
  }
}

.c-newsletter__validation-error {
  color: red;
  display: none;
  font-family: var(--font-secondary), Arial, sans-serif;
  font-size: 0.7em;
  font-weight: 400;
  margin: 10px;
  text-transform: none;
}

.c-newsletter__input {
  background-color: #f2f2f2;
  display: flex;
}

.c-newsletter__input--error {
  box-shadow: 0 0 0 2px red;
}

.c-newsletter__input > * {
  border: none;
  border-radius: 0;
}

.c-newsletter__input > input {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #000;
  font-family: var(--font-primary), Arial, sans-serif;
  font-size: 16px;
  font-style: italic;
  height: auto;
  line-height: 22px;
  margin: inherit;
  outline: none;
  padding: 10px 20px 10px 30px;
  width: 100%;
}

@media (max-width: 61.24em) {
  .c-newsletter__input > input {
    font-size: 0.8em;
  }
}

.c-newsletter__input > input:-ms-input-placeholder {
  font-style: italic;
}

.c-newsletter__input > input::placeholder {
  font-style: italic;
}

.c-newsletter__input > button {
  background-color: #d96973;
  border-radius: 9999px;
  bottom: -4px;
  color: #fff;
  flex: 0 1 1%;
  font-size: 18px;
  line-height: 25px;
  margin: 5px;
  padding: 10px 20px;
  right: -4px;
  white-space: nowrap;
}

@media (max-width: 61.24em) {
  .c-newsletter__input > button {
    font-size: 0.8em;
  }
}

.c-newsletter__input > button:hover {
  background-color: #d45560;
}

.c-newsletter__bottom {
  flex: 0 1 auto;
  padding: 0 40px 20px 0;
}

@media (max-width: 61.24em) {
  .c-newsletter__bottom {
    flex-basis: 100%;
    padding: 0 10px 10px;
  }
}

.c-newsletter__bottom:nth-child(2) {
  padding-left: 40px;
}

@media (max-width: 61.24em) {
  .c-newsletter__bottom:nth-child(2) {
    padding-left: 10px;
  }
}

.c-newsletter__integrity {
  font-family: var(--font-secondary), Arial, sans-serif;
  font-size: 0.7em;
  margin: 10px 10px 0;
}

.c-newsletter__overlay {
  background-color: hsla(0, 0%, 100%, 0.8);
  display: flex;
  height: 100%;
  left: 50%;
  margin: 0 auto;
  max-width: 700px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  transition: all 0.6s ease-out;
  width: 100%;
}

.c-newsletter__overlay--active {
  opacity: 1;
  pointer-events: auto;
}

.c-newsletter__overlay--active .c-newsletter__message {
  animation: frame-in 0.6s ease-in-out normal forwards;
}

.c-newsletter__message {
  align-items: center;
  background-color: #d96973;
  -webkit-clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
  clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
  color: #fff;
  display: flex;
  flex: 1 1 auto;
  font-size: 0.9em;
  justify-content: center;
  padding: 0 25%;
}

.c-newsletter__spinner {
  display: inline-block;
  height: 0.9em;
  width: 0.9em;
}

.c-newsletter__spinner:after {
  animation: spinner-dual-ring 1.2s linear infinite;
  border-color: #fff transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  content: ' ';
  display: block;
  height: 1em;
  margin: 1px;
  width: 1em;
}

.c-newsletter--loading .c-newsletter__form {
  opacity: 0.4;
}

.c-newsletter--success .c-newsletter__image {
  opacity: 0;
}

@supports (-ms-ime-align: auto) {
  .c-newsletter__message {
    margin: 0 15%;
    padding: 0 5%;
    transform: skew(-20deg);
  }

  .c-newsletter__message > span {
    transform: skew(20deg);
  }
}

.c-newsletter--sticky {
  background: transparent;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 120;
}

.c-newsletter--sticky .c-newsletter__form {
  background: #fff;
  box-shadow: 0 2px 10px 5px rgba(153, 71, 78, 0.15);
  margin: 0;
  max-width: none;
  position: relative;
}

.c-newsletter--sticky .c-newsletter__toggle {
  background: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 2px 10px 5px rgba(153, 71, 78, 0.15);
  height: 20px;
  position: absolute;
  right: 15px;
  top: -20px;
  width: 40px;
}

.c-newsletter--sticky .c-newsletter__toggle:before {
  background-image: url(/view-resources/view/content/newsletterSignup/img/chevron-down.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  content: '';
  display: block;
  height: 20px;
  width: 40px;
}

.c-newsletter--sticky .c-newsletter__content {
  background: #fff;
  border-radius: 5px;
  margin: 0;
  padding: 6px;
  z-index: 110;
}

.c-newsletter--sticky .c-newsletter__text {
  display: none;
}

.c-newsletter--sticky .c-newsletter__input > input[type='text'] {
  background: #f1f1f1;
}

.c-newsletter--sticky .c-newsletter__input > input[type='text']:-ms-input-placeholder {
  color: #000;
  font-style: normal;
}

.c-newsletter--sticky .c-newsletter__input > input[type='text']::placeholder {
  color: #000;
  font-style: normal;
}

.c-newsletter--sticky .c-newsletter__integrity {
  color: #000;
  display: none;
  font-family: var(--font-primary), Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin: 10px 10px 0;
  text-transform: none;
}

.c-newsletter--sticky.c-newsletter--mini .c-newsletter__toggle:before {
  background-image: url(/view-resources/view/content/newsletterSignup/img/chevron-up.svg);
}

.c-newsletter--sticky.c-newsletter--mini .c-newsletter__content {
  padding: 3px 0 5px;
}

.c-newsletter--sticky.c-newsletter--mini .c-newsletter__text {
  display: block;
  margin: 0 auto;
}

.c-newsletter--sticky.c-newsletter--mini .c-newsletter__text h4 {
  font-size: 12.8px;
  line-height: 24px;
  margin: 0;
}

.c-newsletter--sticky.c-newsletter--mini .c-newsletter__bottom,
.c-newsletter--sticky.c-newsletter--mini .c-newsletter__text__info {
  display: none;
}

.c-newsletter--sticky.c-newsletter--max .c-newsletter__text {
  display: block;
}

@media (min-width: 46.25em) {
  .c-newsletter--sticky {
    left: auto;
    max-width: 1308px;
    padding: 0 24px;
    right: 300px;
  }

  .c-newsletter--sticky .c-newsletter__form {
    border-radius: 5px 5px 0 0;
    float: right;
    width: 375px;
  }
}

@media (min-width: 75em) {
  .c-newsletter--sticky {
    padding: 0 26px 0 18px;
  }
}

.c-newsletter.lab-is-non-persistent .c-newsletter__form {
  display: none;
}

@keyframes frame-in {
  0% {
    -webkit-clip-path: polygon(49.5% 50%, 50.5% 50%, 50.5% 50%, 49.5% 50%);
    clip-path: polygon(49.5% 50%, 50.5% 50%, 50.5% 50%, 49.5% 50%);
  }

  40% {
    -webkit-clip-path: polygon(57% 0, 58% 0, 43% 100%, 42% 100%);
    clip-path: polygon(57% 0, 58% 0, 43% 100%, 42% 100%);
  }

  to {
    -webkit-clip-path: polygon(25% 0, 100% 0, 75% 100%, 0 100%);
    clip-path: polygon(25% 0, 100% 0, 75% 100%, 0 100%);
  }
}

@keyframes spinner-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}
